import { Box } from "@material-ui/core";
import { PageProps } from "gatsby";
import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { removeMetaRobotsFromSeo } from "../../../helpers/clientSide/removeMetaRobotsFromSeo";
import { mapGenericContent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/mappings/htmlTagsMappedToReactComponents";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import RVOGenericHeroSection from "../../03-organisms/RVOGenericHeroSection/RVOGenericHeroSection";
import RVORegisterTodayCtaSection from "../../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import { RVOFaqDetailsTemplatePageContextInterface } from "./RVOFaqDetailsTemplateInterfaces";
import { useRVOFaqDetailsTemplateStyles } from "./RVOFaqDetailsTemplateStyles";

const RVOFaqDetailsTemplate = (props: PageProps): JSX.Element => {
  const classes = useRVOFaqDetailsTemplateStyles();
  const { question } =
    props.pageContext as RVOFaqDetailsTemplatePageContextInterface;
  const seo = { seo: removeMetaRobotsFromSeo(question.seo) };
  return (
    <>
      <Seo post={seo} />

      <RVOGenericHeroSection title={question.questionFields?.question || ""} />

      <RVOContainer fullWidth={true} paddingBottom={true}>
        <Box className={classes.answer}>
          {replaceHtmlWithFunctionComponent(
            question.questionFields?.answer,
            mapGenericContent
          )}
        </Box>

        <RVORegisterTodayCtaSection />
      </RVOContainer>
    </>
  );
};

export default RVOFaqDetailsTemplate;
