/*
 * Removes keys which have metaRobots from seo object coming from wordpress
 */

export const removeMetaRobotsFromSeo = (
  seo: GatsbyTypes.Maybe<GatsbyTypes.SeoFieldsFragmentFragment>
): GatsbyTypes.SeoFieldsFragmentFragment => {
  let updatedSeo = {} as GatsbyTypes.SeoFieldsFragmentFragment;
  if (seo) {
    updatedSeo = { ...seo };
    Object.keys(seo).forEach((key) => {
      if (key.indexOf("metaRobots") > -1) {
        delete updatedSeo[key as keyof GatsbyTypes.SeoFieldsFragmentFragment];
      }
    });
  }

  return updatedSeo;
};
