import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVOFaqDetailsTemplateStyles = makeStyles((theme: Theme) =>
  createStyles({
    answer: {
      padding: `0 ${theme.spacing(16)}`,
      [theme.breakpoints.only("md")]: {
        padding: `0 ${theme.spacing(10)}`,
      },
      [theme.breakpoints.only("sm")]: {
        padding: `0 ${theme.spacing(5)}`,
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: theme.spacing(3),
        padding: 0,
      },
    },
  })
);
