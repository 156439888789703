import { Box, Typography } from "@material-ui/core";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundGradient } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import { RVOGenericHeroSectionProps } from "./RVOGenericHeroSectionInterfaces";
import { useRVOGenericHeroSectionStyles } from "./RVOGenericHeroSectionStyles";

const RVOGenericHeroSection = (
  props: RVOGenericHeroSectionProps
): JSX.Element => {
  const classes = useRVOGenericHeroSectionStyles();

  return (
    <RVOContainer
      paddingBottom={true}
      background={RVOBackgroundGradient.SecondaryLightToMediumBlue}
      fullWidth={true}
    >
      <Box className={classes.wrapper}>
        <Typography variant="h1" className={classes.typography}>
          {props.title}
        </Typography>

        <Typography variant="body1" className={classes.typography}>
          {props.subtitle}
        </Typography>
      </Box>
    </RVOContainer>
  );
};

export default RVOGenericHeroSection;
