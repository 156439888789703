import { Box, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { pricingPath } from "../../../helpers/general/frontendUrlPaths";
import theme from "../../../layouts/theme";
import RVOLinkButton from "../../01-atoms/RVOLinkButton/RVOLinkButton";
import RVORegisterNowCtaButton from "../../01-atoms/RVORegisterNowCtaButton/RVORegisterNowCtaButton";

export interface RVORegisterOrComparePricesButtonsProps {
  invertColors?: boolean;
}

const RVORegisterOrComparePricesButtons = ({
  invertColors,
}: RVORegisterOrComparePricesButtonsProps): JSX.Element => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection={isSmallScreen ? "column" : "row"}
      style={{ gap: "1rem" }}
    >
      <RVORegisterNowCtaButton invertColors={true} />

      <RVOLinkButton
        buttonProps={{
          color: invertColors ? "secondary" : "primary",
          variant: "outlined",
        }}
        href={pricingPath}
        // eslint-disable-next-line i18next/no-literal-string
      >
        {t("home.hero.buttons.comparePrices")}
      </RVOLinkButton>
    </Box>
  );
};

export default RVORegisterOrComparePricesButtons;
