import { ComponentWithClassNameProps } from "@nvon/react-toolbox";
import { useTranslation } from "react-i18next";
import { resolvioAppRegisterUrl } from "../../../helpers/clientSide/constants";
import {
  RVOTrackingEvent,
  trackEvent,
} from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import RVOLinkButton from "../RVOLinkButton/RVOLinkButton";

export interface RVOStartNowCtaButtonProps extends ComponentWithClassNameProps {
  label?: string;
  invertColors?: boolean;
}

const RVORegisterNowCtaButton = (
  props: RVOStartNowCtaButtonProps
): JSX.Element => {
  const { t } = useTranslation();

  return (
    <RVOLinkButton
      buttonProps={{
        color: props.invertColors ? "secondary" : "primary",
        onClick: () => trackEvent(RVOTrackingEvent.RegisterOnAppClicked),
      }}
      buttonClass={props.className}
      href={resolvioAppRegisterUrl}
    >
      {props.label || t("header.registerNow")}
    </RVOLinkButton>
  );
};

export default RVORegisterNowCtaButton;
