import { Card } from "@material-ui/core";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import RVORegisterTodayCtaBox from "../../02-molecules/RVORegisterTodayCtaBox/RVORegisterTodayCtaBox";

const RVORegisterTodayCtaSection = (): JSX.Element => {
  return (
    <RVOContainer paddingBottom={true}>
      <Card style={{ padding: 0 }}>
        <RVORegisterTodayCtaBox />
      </Card>
    </RVOContainer>
  );
};

export default RVORegisterTodayCtaSection;
