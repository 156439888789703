import { Box } from "@material-ui/core";

export interface RVOTrustLogosProps {
  invertColors?: boolean;
}

const RVOTrustLogos = ({ invertColors }: RVOTrustLogosProps): JSX.Element => {
  return (
    <Box>
      <Box display="flex" flexDirection="row" style={{ gap: "2rem" }}>
        <img
          width={160}
          height="auto"
          src={
            !invertColors
              ? "/app/trust/badge_black.png"
              : "/app/trust/badge_white.png"
          }
        />
      </Box>
    </Box>
  );
};

export default RVOTrustLogos;
