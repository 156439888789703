import { Button, ButtonProps } from "@material-ui/core";
import { clsx } from "clsx";
import { Link } from "gatsby";
import { useMemo } from "react";
import { RVOLinkButtonProps } from "./RVOLinkButtonInterfaces";

const defaultButtonProps: Partial<ButtonProps> = {
  variant: "contained",
};

/**
 * A button that links to a page.
 */
const RVOLinkButton = (props: RVOLinkButtonProps): JSX.Element => {
  const buttonProps: ButtonProps = useMemo(
    () => ({
      ...defaultButtonProps,
      ...props.buttonProps,
      component: Link,
      to: props.href,
      href: props.href,
      state: props.state,
      className: clsx(props.buttonClass, props.buttonProps?.className),
    }),
    [props.buttonClass, props.buttonProps, props.href, props.state]
  );

  return <Button {...buttonProps}>{props.children}</Button>;
};

export default RVOLinkButton;
