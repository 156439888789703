import { createStyles, makeStyles } from "@material-ui/core";

export const useRVOGenericHeroSectionStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: "80%",
      margin: "0 auto",
    },
    typography: {
      textAlign: "center",
    },
  })
);
