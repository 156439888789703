import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RVOTrustLogos from "../../01-atoms/RVOHeroTrustLogos/RVOHeroTrustLogos";
import RVORegisterOrComparePricesButtons from "../RVORegisterOrComparePricesButtons/RVORegisterOrComparePricesButtons";

const RVORegisterTodayCtaBox = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      style={{
        background:
          "radial-gradient(circle, rgba(81,96,117,1) 0%, rgba(29,48,84,1) 100%)",
        color: theme.palette.common.white,
        minHeight: "60vh",
        display: "flex",
        flexDirection: isTablet ? "column-reverse" : "row",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      {!isTablet && (
        <Box
          style={{
            position: "absolute",
            bottom: -50,
            left: -70,
            transform: "rotate(5deg)",
          }}
        >
          <img
            style={{
              borderTopRightRadius: theme.shape.borderRadius,
              borderTopLeftRadius: theme.shape.borderRadius,
            }}
            width={450}
            height="auto"
            src="/app/screenshots/workspace_switcher.png"
          />
        </Box>
      )}

      {!isTablet && (
        <Box
          style={{
            position: "absolute",
            bottom: -70,
            right: -30,
            transform: "rotate(-7deg)",
          }}
        >
          <img
            style={{
              borderTopRightRadius: theme.shape.borderRadius,
              borderTopLeftRadius: theme.shape.borderRadius,
            }}
            width={300}
            height="auto"
            src="/app/screenshots/sign.png"
          />
        </Box>
      )}

      {isTablet && (
        <Box
          width="80%"
          style={{
            transform: "translateY(20px)",
          }}
        >
          <img
            style={{
              borderTopRightRadius: theme.shape.borderRadius,
              borderTopLeftRadius: theme.shape.borderRadius,
            }}
            width="100%"
            height="auto"
            src="/app/screenshots/beschluss-browser.png"
          />
        </Box>
      )}

      <Box
        style={{
          padding: !isTablet
            ? theme.spacing(4, 4, 4, 4)
            : theme.spacing(4, 1, 4, 1),
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(2),
          textAlign: "center",
        }}
      >
        <Typography
          style={{ color: "inherit", textAlign: "inherit", marginBottom: 0 }}
          variant="h3"
        >
          {t("registerTodayCta.title")}
        </Typography>

        <Typography
          style={{
            color: "inherit",
            textAlign: "inherit",
            maxWidth: isTablet ? "80%" : "40%",
          }}
          variant="body1"
        >
          {t("registerTodayCta.claim")}
        </Typography>

        <Box>
          <RVORegisterOrComparePricesButtons invertColors={true} />
        </Box>

        <RVOTrustLogos invertColors={true} />
      </Box>
    </Box>
  );
};

export default RVORegisterTodayCtaBox;
